import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'BasicMake',
            component: resolve => require(['@/view/basic.vue'], resolve)
        },
        {
            path: '/vip',
            name: 'VipMake',
            component: resolve => require(['@/view/vipMake.vue'], resolve)
        },
        {
            path: '/other',
            name: 'otherSurvey',
            component: resolve => require(['@/view/otherSurvey.vue'], resolve)
        },
        {
            path: '/dip',
            name: 'DipMake',
            component: resolve => require(['@/view/dipMake.vue'], resolve)
        },
        {
            path: '/hsbc',
            name: 'hsbcSurvey',
            component: resolve => require(['@/view/hsbc.vue'], resolve)
        }
    ]
})
